import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/sale-order.png'
import _imports_1 from '@/assets/img/buy-order.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "h-t" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "h-t" }
const _hoisted_6 = { class: "description" }

import { ref, watch, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { lang } from '@/lang';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    show: { type: Boolean }
  },
  setup(__props: any) {

const router = useRouter();
const props = __props;

const { show } = toRefs(props);
const showPopup = ref(show.value);

watch(show, () => {
  showPopup.value = true;
});

//跳转
const onType = (type: number) => {
  showPopup.value = false;
  router.push({
    path: '/pendingOrders',
    query: {
      type: type,
      id: 0,
    },
  });
};
// 取消按钮
const Cancel = () => {
  showPopup.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    round: "",
    show: showPopup.value,
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((showPopup).value = $event)),
    position: "bottom",
    "safe-area-inset-bottom": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(lang).v.selectType), 1),
        _createElementVNode("div", {
          class: "card",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onType(2)))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            class: "imgItem",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("text", _hoisted_3, _toDisplayString(_unref(lang).v.sellPoints), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(lang).v.sellUsers), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "card",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (onType(1)))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "imgItem",
            src: _imports_1,
            alt: ""
          }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("text", _hoisted_5, _toDisplayString(_unref(lang).v.purchasePoints), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(lang).v.canPointsUsers), 1)
          ])
        ]),
        _createVNode(_component_van_button, {
          class: "cancelBtn",
          block: "",
          onClick: Cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(lang).v.cancel), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})