<template>
  <div class="out">
    <Top />
    <div class="main" v-loading="faceRecord.loading">
      <div class="nav">
        <div class="nav-left">
          <div
            :class="['title', { activity: buyOrSell }]"
            @click="onchangeBuyOrSell(true)"
          >
            {{ lang.v.mySell }}
          </div>
          <div
            :class="['title', { activity: !buyOrSell }]"
            @click="onchangeBuyOrSell(false)"
          >
            {{ lang.v.myBuy }}
          </div>
        </div>
        <div class="nav-right">
          <span class="right-btn" @click="onGoAdd">{{
            lang.v.placeOrder
          }}</span>
        </div>
      </div>
      <div class="menu">
        <div class="menu-left">
          <el-dropdown @command="onChangePayType">
            <span class="payment-method" v-show="!buyOrSell">
              {{ lang.v.method }}
              <el-icon><ArrowDown /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>{{ lang.v.whole }}</el-dropdown-item>
                <el-dropdown-item
                  v-for="item in payTypeList"
                  :key="item.id"
                  :command="item.id"
                >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="menu-right">
          <img src="~@/assets/img/myOrderImg.png" alt="" class="right-img" />
          <span class="payment-method" @click="myOrder">{{
            lang.v.myOrder
          }}</span>
        </div>
      </div>
      <div class="transaction-list">
        <div
          class="item"
          v-for="item in faceRecord.transactionList"
          :key="item"
        >
          <div class="item-top">
            <div class="top-nicname">
              <img src="~@/assets/img/Ellipse.png" alt="" class="avatar" />
              <div class="nicname">{{ lang.v.anonymousUser }}</div>
            </div>
            <div class="top-transactions">
              <div>{{ lang.v.deal }} {{ item?.number || 0 }}</div>
              <div v-show="item.payWay" class="pay-way">
                {{ item.payWayName }}
              </div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="bottom-left">
              <div class="left-top">
                <span class="icno">{{ lang.v.unit }}</span>
                <span class="money">{{ item.amount }}</span>
              </div>
              <div class="left-bottom" v-show="!buyOrSell">
                <span class="text">{{ lang.v.sell }}</span>
                <span class="integral"
                >{{ item.integral }}{{ lang.v.integral }}</span
                >
              </div>
              <div class="left-bottom" v-show="buyOrSell">
                <span class="text">{{ lang.v.demand }}</span>
                <span class="integral"
                >{{ item.integral }}{{ lang.v.integral }}</span
                >
              </div>
            </div>
            <div class="bottom-right">
              <div class="right-top">
                <img
                  src="~@/assets/img/iconTime.png"
                  alt=""
                  class="icon-time"
                />
                <span class="text"
                >{{ faceRecord.timeLimit.toFixed(0)
                }}{{ lang.v.minute }}</span
                >
              </div>
              <div class="right-bottom" @click="onGoDetail(item)">
                <div class="right-btn" v-show="buyOrSell">
                  <span class="text">{{ lang.v.sellOut }}</span>
                </div>
                <div
                  :class="['right-btn', { activity: !buyOrSell }]"
                  v-show="!buyOrSell"
                >
                  <span class="text">{{ lang.v.purchase }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page" v-show="faceRecord.total != 0">
        <van-pagination
          v-model="faceRecord.page"
          :total-items="faceRecord.total"
          :items-per-page="4"
          @change="onChangePage"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <div
      class="order"
      v-show="faceRecord.pendingTotal != 0"
      @click="onPandingOrder"
    >
      <img src="~@/assets/img/orderImg.png" alt="" class="order-img" />
      <span class="title">{{ lang.v.pendingOrders }}</span>
    </div>
    <PopupOreder :show="shows" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import {
  fetchTransactionServeList,
  fetchTransactionServeNumber,
  fetchTransactionServePayment,
  fetchTransactionServePendingOrder,
  fetchTransactionServeTime,
} from '@/api/fetcher';
import Top from '@/components/Top/top.vue';
import { useRouter } from 'vue-router';
import PopupOreder from '@/components/PopupOrder/index.vue';
import dataCenter from '@/dataCenter';
import { lang } from '@/lang';
const shows = ref(0);
let timer = null;
//挂单弹窗
const onGoAdd = () => {
  shows.value += 1;
};

const faceRecord = reactive({
  page: 1,
  /** 买卖单列表 */
  transactionList: [],
  loading: true,
  /** 单总数 */
  total: 0,
  /** 商品类型 1：买 2：卖 */
  type: 1,
  /** 支付方式 */
  payWay: null,
  // 支付方式名称
  payWayName: '',
  /** 待处理订单数 */
  pendingTotal: 0,
  // 待处理订单
  pendingOrding: [],
  /** 时间限制 */
  timeLimit: 10,
});

//获取订单列表函数
const getTransactionList = async () => {
  //获取支付方式
  getTransactionPayment();
  faceRecord.loading = true;
  //获取订单列表
  const res = await fetchTransactionServeList({
    page: faceRecord.page,
    limit: 4,
    status: 1,
    type: faceRecord.type,
    payWay: faceRecord.payWay,
  }).catch(() => {
    faceRecord.loading = false;
    return 0;
  });
  faceRecord.total = res.total;
  faceRecord.transactionList = res.list;
  let userIds = faceRecord.transactionList.map(item => {
    return item.creator;
  });
  faceRecord.loading = true;
  userIds = [...new Set(userIds)];
  //获取每单成交笔数
  const numberRes = await fetchTransactionServeNumber({
    userIds: userIds.toString(),
  }).finally(() => {
    faceRecord.loading = false;
  });
  if (numberRes) {
    faceRecord.transactionList = res.list.map(item => {
      return {
        ...item,
        number: numberRes[item.creator],
        payWay: item.payWay,
        payWayName: enumeratePaymentMethods(item.payWay),
      };
    });
  }
};

//跳转待处理页面
const onPandingOrder = () => {
  router.push({
    path: '/TransactionServePendingOrder',
  });
};
//获取待处理订单
const getTransactionPendingOrder = async () => {
  const res = await fetchTransactionServePendingOrder({
    page: faceRecord.page,
    limit: 4,
    status: '0,1,4,5',
  });
  if (res) {
    faceRecord.pendingTotal = res.total;
    faceRecord.pendingList = res.list;
  }
};
//枚举支付方式
const enumeratePaymentMethods = id => {
  const payTypeObj = payTypeList.value?.find(item => item.id == id);
  if (payTypeObj) {
    return payTypeObj.name;
  }
};
//获取支付方式
const getTransactionPayment = async () => {
  const res = await fetchTransactionServePayment().catch(() => {
    faceRecord.loading = false;
  });
  if (res) {
    payTypeList.value = res;
  }
};
//切换买卖函数
const onchangeBuyOrSell = type => {
  buyOrSell.value = type;
  faceRecord.type = buyOrSell.value ? 1 : 2;
  faceRecord.page = 1;
  getTransactionList();
  getTransactionPendingOrder();
};
//页码切换函数
const onChangePage = () => {
  getTransactionList();
};
//选择支付方式函数
const onChangePayType = type => {
  faceRecord.payWay = type;
  getTransactionList();
};
// 获取时间限制
const getLimitTime = async () => {
  const data = await fetchTransactionServeTime();
  if (data) {
    dataCenter.payStatus.setTimeLimitMinutes(data);
    faceRecord.timeLimit = dataCenter.payStatus.timeLimitMinutes;
  }
};
const router = useRouter();

// 跳转我的订单
const myOrder = () => {
  router.push({
    path: '/myOrder',
  });
};
//跳转详情页
const onGoDetail = item => {
  dataCenter.payStatus.setDataPayStatus(item);
  sessionStorage.setItem(
    'TransactionServeList',
    JSON.stringify({ page: faceRecord.page, type: buyOrSell.value })
  );
  router.push({
    path: '/transactionServeDetails',
    query: {
      id: item.id,
      type: buyOrSell.value ? 2 : 1,
    },
  });
};
onMounted(() => {
  buyOrSell.value = true;
  if (sessionStorage.getItem('TransactionServeList')) {
    faceRecord.page = JSON.parse(
      sessionStorage.getItem('TransactionServeList')
    ).page;
    buyOrSell.value = JSON.parse(
      sessionStorage.getItem('TransactionServeList')
    ).type;
    faceRecord.type = buyOrSell.value ? 1 : 2;
    faceRecord.total = 4 * (faceRecord.page - 1) + 1;
    sessionStorage.removeItem('TransactionServeList');
  }
  getTransactionList();
  timer = setInterval(() => {
    getTransactionPendingOrder();
    if (faceRecord.pendingTotal != 0) {
      clearInterval(timer);
    }
  }, 10000);

  getLimitTime();
});
onUnmounted(() => {
  clearTimeout(timer);
});
//支付方式列表
const payTypeList = ref();
// 买卖切换
const buyOrSell = ref(false);
</script>
<style lang="less" scoped>
.out {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  overflow-y: scroll;
}
.main {
  background-color: #f6f6f8;
  padding: 0.16rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-left {
      display: flex;
      transition: linear 1s;
      align-items: center;
      width: 1.3rem;
      height: 0.34rem;
      padding: 0.02rem;
      background: #eaeaea;
      border-radius: 0.06rem;
      .title {
        width: 0.62rem;
        padding: 0.1rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.14rem;
        color: #858c9f;
        line-height: 0.16rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .activity {
        background: #ffffff;
        border-radius: 0.06rem;
        color: #222222;
      }
    }
    .nav-right {
      height: 0.34rem;
      background: #ed931e;
      border-radius: 0.06rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .right-btn {
        padding: 0.08rem 0.1rem;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffffff;
        line-height: 0.18rem;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .menu {
    height: 0.16rem;
    font-weight: 600;
    font-size: 0.12rem;
    color: #000000;
    line-height: 0.16rem;
    display: flex;
    margin-top: 0.16rem;
    justify-content: space-between;
    align-items: center;
    .menu-left {
      .payment-method {
        margin-right: 0.04rem;
        font-weight: 600;
        font-size: 0.12rem;
        color: #000000;
        line-height: 0.16rem;
      }
      :deep(.el-tooltip__trigger:focus-visible) {
        outline: unset;
      }
    }
    .menu-right {
      display: flex;
      align-items: center;
      .right-img {
        margin-right: 0.04rem;
        width: 0.18rem;
        height: 0.18rem;
      }
    }
  }
  .transaction-list {
    margin-top: 0.06rem;
    .item {
      margin-top: 0.1rem;
      padding: 0.12rem;
      // width: 3.47rem;
      height: 1.34rem;
      background: #ffffff;
      border-radius: 0.08rem;
      .item-top {
        .top-nicname {
          display: flex;
          align-items: center;
          .nicname {
            height: 0.2rem;
            font-weight: 400;
            font-size: 0.14rem;
            color: #000000;
            line-height: 16px;
          }
        }
        .top-transactions {
          margin-top: 0.06rem;
          height: 0.16rem;
          font-weight: 400;
          font-size: 0.11rem;
          color: #858c9f;
          line-height: 0.16rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .pay-way {
            padding-right: 0.04rem;
            border-radius: 0.01rem;
            border-right: 0.03rem solid #67d234;
          }
        }
      }
      .item-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 0.08rem;
        .bottom-left {
          .left-top {
            display: flex;
            align-items: baseline;
            line-height: 0.18rem;
            .icno {
              font-weight: 500;
              font-size: 0.12rem;
              color: #222222;
            }
            .money {
              margin-left: 0.03rem;
              font-weight: 500;
              font-size: 0.22rem;
              color: #000000;
            }
            .integral {
              font-weight: 400;
              font-size: 0.12rem;
              color: #858c9f;
            }
          }
          .left-bottom {
            margin-top: 0.29rem;
            font-weight: 400;
            font-size: 0.12rem;
            line-height: 0.18rem;
            .text {
              margin-right: 0.08rem;
              color: #858c9f;
            }
            .integral {
              color: #222222;
            }
          }
        }
        .bottom-right {
          display: flex;
          flex-direction: column;
          align-items: end;
          .right-top {
            display: flex;
            align-items: center;
            .icon-time {
              width: 0.12rem;
              height: 0.12rem;
            }
            .text {
              margin-left: 0.04rem;
              padding: 0.02rem;
              font-weight: 400;
              font-size: 0.12rem;
              color: #858c9f;
              line-height: 0.16rem;
              border-bottom: 0.01rem #858c9f dashed;
            }
          }
          .right-bottom {
            margin-top: 0.12rem;
            .right-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 0.8rem;
              height: 0.34rem;
              background: #dc4343;
              border-radius: 0.06rem;
              .text {
                font-weight: 400;
                font-size: 0.18rem;
                color: #ffffff;
                line-height: 0.18rem;
                text-align: right;
              }
            }
            .activity {
              background: #47c754;
            }
          }
        }
      }
    }
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.1rem;
  }
}
.order {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  right: 0.08rem;
  bottom: 1.94rem;
  width: 1.3rem;
  height: 0.32rem;
  background: #444444;
  border-radius: 0.16rem;
  .order-img {
    width: 0.24rem;
    height: 0.24rem;
  }
  .title {
    font-weight: 500;
    font-size: 0.12rem;
    color: #ffffff;
    line-height: 0.16rem;
  }
}
.avatar {
  margin-right: 0.05rem;
  width: 0.22rem;
  height: 0.22rem;
}
</style>
